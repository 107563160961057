import { Component, ref, toRefs } from 'vue'
import ModalPentesting from '@/components/product-modals/ModalPentesting.vue'
import ModalCPM from '@/components/product-modals/ModalCPM.vue'
import { useTrackEvents } from '@/composables'

import { EventType, MetadataKeys } from '@/types/GainsightEvents'

type State = {
  modalComponent: Component | null
  isModalShown: boolean
}

const state = ref<State>({
  modalComponent: null,
  isModalShown: false,
})

const modalProductMapping: { [key: string]: Component } = {
  pentesting: ModalPentesting,
  consent_and_preference_management: ModalCPM,
}

const useEnquireModal = () => {
  const trackEvents = useTrackEvents()

  const trackEnquireEvent = (
    productTitle: 'Pentesting' | 'Consent & Preference Management',
    productType: 'security' | 'compliance'
  ) => {
    trackEvents({
      eventType: EventType.AppsSectionEnquireNow,
      metadata: {
        [MetadataKeys.AppTitle]: productTitle,
        [MetadataKeys.AppType]: productType,
      },
    })
  }

  const open = (productType: string) => {
    state.value.modalComponent = modalProductMapping[productType]
    state.value.isModalShown = true
  }

  const close = () => {
    state.value.modalComponent = null
    state.value.isModalShown = false
  }

  return {
    ...toRefs(state.value),
    open,
    close,
    trackEnquireEvent,
  }
}

export default useEnquireModal
