import axios from 'axios'
import * as Sentry from '@sentry/vue'
import type { CopilotMetadata } from '@/types'

export async function getCopilotAccessToken(
  metadata: CopilotMetadata
): Promise<string | undefined> {
  try {
    const response = await axios.post('/copilot-access-token', metadata)
    return response.data.accessToken
  } catch (error) {
    console.warn('Failed to get copilot access token')
    Sentry.captureException(error)
    return undefined
  }
}
