import axios from 'axios'
import type { Tag } from '@/composables/taskModal'

export type UpdateTaskPayload = {
  title: string
  description: string
  due_at: Date | string | null
  assignee_id: string | null
  task_list_id: string | null
  tag_list: Tag[]
}

export type FetchTaskParams = {
  assignee_id?: string
  section_id?: string[]
  tag_list?: { context: string; value: string }[]
}

export const getTasks = (companyId: string, params: FetchTaskParams = {}) => {
  return axios.get(`/tasks/v1/companies/${companyId}/tasks`, {
    params: {
      ...params,
      tag_list: params.tag_list ? JSON.stringify(params.tag_list) : undefined,
    },
  })
}

export const createTask = (companyId: string, payload: FormData) =>
  axios.post(`/tasks/v1/companies/${companyId}/tasks`, payload)

export const updateTask = (
  companyId: string,
  taskId: string,
  payload: { data: UpdateTaskPayload }
) => axios.patch(`/tasks/v1/companies/${companyId}/tasks/${taskId}`, payload)

export const createTaskSection = (companyId: string, label: string) => {
  const response = axios.post(
    `/tasks/v1/companies/${companyId}/sections`,
    {
      data: { label },
    },
    {
      validateStatus: (status) => {
        // status 409 case is returned when the section already exists
        return (status >= 200 && status < 300) || status === 409
      },
    }
  )
  return response
}
