<template>
  <footer class="shadow-view mt-auto z-20 w-full bg-white text-dark-blue-500">
    <ul class="flex gap-4 max-w-7xl mx-auto py-6 px-16">
      <li>
        <a
          class="hover:underline hover:text-dark-red-500"
          :href="getT(Group.privacy_policy, Key.link)"
          target="_blank"
          >{{ getT(Group.privacy_policy, Key.text) }}</a
        >
      </li>
      <li>
        <a
          class="hover:underline hover:text-dark-red-500"
          :href="getT(Group.imprint, Key.link)"
          target="_blank"
          >{{ getT(Group.imprint, Key.text) }}</a
        >
      </li>
    </ul>
  </footer>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

enum Group {
  privacy_policy = 'privacy_policy',
  imprint = 'imprint',
}

enum Key {
  text = 'text',
  link = 'link',
}

const getT = (group: Group, key: Key) => t(`footer.${group}.${key}`)
</script>
