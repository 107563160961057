import { getPermissions, getUser } from '@/api/user.api'
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { Loaders, useLoadingStore } from './loading'
import { getAnonymousAccessToken } from '@/api/anonymousAccessToken.api'

const powerUserRoles = [
  'customer',
  'academy_maintainer',
  'policies_app_admin',
  'reports_app_admin',
] as const

export type Role = (typeof powerUserRoles)[number]
export interface User {
  administrative: boolean
  id: string
  role: string
  email: string
  name: string
  roles: {
    [key: string]: string[]
  }
  permissions: {
    [key: string]: string[]
  }
  companyIds: string[]
  anonymousAccessToken?: string
}

export const useUserStore = defineStore('user', () => {
  const loadingStore = useLoadingStore()
  const user = ref<User>()
  const isLoggedIn = computed(() => Boolean(user.value))
  const isAdmin = computed(() => user.value?.administrative)
  const userTokenForArchbeeLinks = computed(
    () => user.value?.anonymousAccessToken
  )
  const setUser = (newUser: User) => {
    user.value = newUser
  }
  const fetchUser = async () => {
    loadingStore.startLoading(Loaders.user)
    try {
      const userResponse = await getUser()
      const user = userResponse.data
      const permissions = await getPermissions()
      user.permissions = permissions.data
      user.companyIds = Object.keys(user.permissions)
      const jwtToken = await getAnonymousAccessToken()
      if (jwtToken) user.anonymousAccessToken = jwtToken
      setUser(user)
    } catch (error) {
      console.error(error)
    } finally {
      loadingStore.stopLoading(Loaders.user)
    }
  }

  const hasPermission = (
    platformId: string,
    universalCompanyId: string,
    permission: string
  ) => {
    if (user.value === undefined) return false

    if (platformId in user.value.permissions) {
      return user.value.permissions[platformId].includes(permission)
    }
    if (universalCompanyId in user.value.permissions) {
      return user.value.permissions[universalCompanyId].includes(permission)
    }

    return false
  }

  const isAcademyOnly = (platformId?: string) => {
    if (!platformId || !user.value) return false
    return (
      platformId in user.value.roles &&
      user.value.roles[platformId].includes('academy') &&
      user.value.roles[platformId].length === 1
    )
  }

  const isPowerUser = (companyId?: string): boolean => {
    if (!companyId || !user.value) return false
    if (!user.value?.roles[companyId]) return false

    return powerUserRoles.some((role) =>
      user.value!.roles[companyId].includes(role)
    )
  }

  const hasAuditFunctionRole = (companyId?: string): boolean => {
    if (!companyId || !user.value) return false
    if (!user.value?.roles[companyId]) return false

    return user.value!.roles[companyId].some((role) => role.includes('audit_'))
  }

  return {
    user,
    userTokenForArchbeeLinks,
    isAdmin,
    isLoggedIn,
    isPowerUser,
    isAcademyOnly,
    setUser,
    fetchUser,
    hasPermission,
    hasAuditFunctionRole,
  }
})
