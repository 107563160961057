import { reactive } from 'vue'
import axios from 'axios'

import { useLoadingStore, Loaders } from '@/stores/loading'

interface AuthConfig {
  url: string
  realm: string
  clientId: string
}

interface EnvState {
  authConfig?: AuthConfig
  gainsightPxTag?: string
  environment: string
  newsUrl: string
  tasksUrl: string
  academyUrl: string
  reportsUrl: string
  privacyUrl: string
  infosecUrl: string
  picCrmUrl: string
  profileUrl: string
  securityQuestionnairesUrl: string
  policiesUrl: string
  configAppUrl: string
  frameworksAppUrl: string
  cookieManagerUrl: string
  knowledgeCenterUrl: string
  redworldAppUrl: string
  integrationsAppUrl: string
  helpSpaceUrl: string
  helpSpaceDeUrl: string
  copilotWidgetUrl: string
}

interface State {
  fetched: boolean
  env?: EnvState
  error?: string
}

const fallbackEnvData = {
  environment: 'production',
  tasksUrl: 'https://tasks.dataguard.de',
  academyUrl: 'https://academy.dataguard.de',
  reportsUrl: 'https://reports.dataguard.de',
  privacyUrl: 'https://privacy.dataguard.de',
  infosecUrl: 'https://infosec.dataguard.de',
  picCrmUrl: 'https://app.dataguard.de',
  newsUrl: 'https://news.dataguard.de',
  configAppUrl: 'https://config.dataguard.de',
  policiesUrl: 'https://policies.dataguard.de',
  securityQuestionnairesUrl: 'https://sec-questionnaires.dataguard.de',
  profileUrl: 'https://auth.dataguard.de/realms/dataguard/account',
  frameworksAppUrl: 'https://frameworks.dataguard.de',
  cookieManagerUrl: 'https://cookiechimp.com/users/auth/dataguard_development',
  knowledgeCenterUrl: 'https://help.dataguard.de',
  redworldAppUrl: 'https://ui-v3.dataguard.de',
  integrationsAppUrl: 'https://integrations-ui.dataguard.de',
  helpSpaceUrl: 'https://knowledge.dataguard.de/getting-started',
  helpSpaceDeUrl: 'https://knowledge.dataguard.de/erste-schritte',
  copilotWidgetUrl: 'https://faq.ai.dataguard.de',
}

const state = reactive<State>({
  env: fallbackEnvData,
  fetched: false,
  error: undefined,
})

const useEnv = () => {
  const { startLoading, stopLoading } = useLoadingStore()
  const fetchEnvData = async () => {
    startLoading(Loaders.root)
    try {
      const { data } = await axios.get<EnvState>(`/env-data`)
      state.env = {
        ...state.env,
        ...data,
      }
      state.fetched = true
    } catch {
      state.error =
        'Something went wrong while trying to fetch environment data'
    } finally {
      stopLoading(Loaders.root)
    }
  }

  return {
    env: state.env as EnvState,
    fetchEnvData,
  }
}

export default useEnv
