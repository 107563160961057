export enum AppKeys {
  KnowledgeCenter = 'knowledge-center',
  Frameworks = 'frameworks',
  News = 'news',
  Academy = 'academy',
  Profile = 'profile',
  Configuration = 'configuration',
  Tasks = 'tasks',
  Policies = 'policies',
  Integrations = 'integrations',
  GapAnalysis = 'gap-analysis',
  Assets = 'assets',
  Risks = 'risks',
  IsmsDocumentation = 'isms-documentation',
  InternalAudit = 'internal-audit',
  ManagementReview = 'management-review',
  SecurityQuestionnaires = 'security-questionnaires',
  Pentesting = 'pentesting',
  IRequestCenter = 'i-request-center',
  Audit = 'audit',
  DataSubjectRequests = 'data-subject-requests',
  Documentation = 'documentation',
  PrivacyPartnerManagement = 'privacy-partner-management',
  PrivacyPolicy = 'privacy-policy',
  Resources = 'resources',
  RequestCenter = 'request-center',
  CookieManager = 'cookie-manager',
  ConsentAndPreferenceManagement = 'consent_and_preference_management',
  Whistleblowing = 'whistleblowing',
}
