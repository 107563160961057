import { createI18n } from 'vue-i18n'
import de from '@/locales/de.json'
import en from '@/locales/en.json'

export const FALLBACK_LOCALE = 'en'
export const VALID_LOCALES = ['en', 'de']

export type ValidLocales = 'en' | 'de'

export default function useTranslations() {
  const urlFromPath = window.location.pathname.split('/').slice(1, 2)[0]
  const i18n = createI18n({
    legacy: false,
    locale: urlFromPath || localStorage.getItem('language') || FALLBACK_LOCALE,
    fallbackLocale: FALLBACK_LOCALE,
    messages: { de, en },
  })

  return i18n
}
