<template>
  <li class="relative pl-4 text-blue-500">
    <slot></slot>
  </li>
</template>

<style scoped lang="postcss">
li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.55rem;
  height: 0.3rem;
  width: 0.3rem;
  border: 1px solid #171f3f;
  border-width: 2px 2px 0 0;
  rotate: 45deg;
}
</style>
