import {
  DgxAppIconAcademy,
  DgxAppIconNews,
  DgxAppIconKnowledgeBase,
  DgxAppIconFrameworks,
  DgxAppIconAccount,
  DgxAppIconConfiguration,
  DgxAppIconTasks,
  DgxAppIconPolicies,
  DgxAppIconIntegrations,
  DgxAppIconIGeneric,
  DgxAppIconAssets,
  DgxAppIconRisks,
  DgxAppIconSecurityQuestionnaires,
  DgxAppIconPGeneric,
  DgxAppIconCookieChimp,
  DgxAppIconCPM,
  DgxAppIconDSR,
  DgxAppIconAssessments,
  DgxAppIconVendors,
  DgxAppIconPPG,
  DgxAppIconRopa,
  DgxAppIconWhistleblowing,
  DgxAppIconPentesting,
  // DgxAppIconAppSecurity
} from '@dataguard/product-design-system'
import { type AppItem } from '../appLinks'
import { AppKeys, AppTypes, AppTitles } from '@/types'

const allLinks: AppItem[] = [
  {
    key: AppKeys.KnowledgeCenter,
    title: AppTitles.KnowledgeCenter,
    type: AppTypes.Generic,
    icon: DgxAppIconKnowledgeBase,
    locked: false,
  },
  {
    key: AppKeys.Frameworks,
    title: AppTitles.Frameworks,
    type: AppTypes.Generic,
    icon: DgxAppIconFrameworks,
    locked: false,
  },
  {
    key: AppKeys.News,
    title: AppTitles.News,
    type: AppTypes.Generic,
    icon: DgxAppIconNews,
    locked: false,
  },
  {
    key: AppKeys.Academy,
    title: AppTitles.Academy,
    type: AppTypes.Generic,
    icon: DgxAppIconAcademy,
    locked: false,
  },
  {
    key: AppKeys.Profile,
    title: AppTitles.Profile,
    type: AppTypes.Generic,
    icon: DgxAppIconAccount,
    locked: false,
  },
  {
    key: AppKeys.Configuration,
    title: AppTitles.Configuration,
    type: AppTypes.Generic,
    icon: DgxAppIconConfiguration,
    locked: false,
  },
  {
    key: AppKeys.Tasks,
    title: AppTitles.Tasks,
    type: AppTypes.Generic,
    icon: DgxAppIconTasks,
    locked: false,
  },
  {
    key: AppKeys.Policies,
    title: AppTitles.Policies,
    type: AppTypes.Generic,
    icon: DgxAppIconPolicies,
    locked: false,
  },
  {
    key: AppKeys.Integrations,
    title: AppTitles.Integrations,
    type: AppTypes.Generic,
    icon: DgxAppIconIntegrations,
    locked: false,
  },
  {
    key: AppKeys.GapAnalysis,
    title: AppTitles.GapAnalysis,
    type: AppTypes.Security,
    icon: DgxAppIconAssessments,
    locked: false,
  },
  {
    key: AppKeys.Assets,
    title: AppTitles.Assets,
    type: AppTypes.Security,
    icon: DgxAppIconAssets,
    locked: false,
  },
  {
    key: AppKeys.Risks,
    title: AppTitles.Risks,
    type: AppTypes.Security,
    icon: DgxAppIconRisks,
    locked: false,
  },
  {
    key: AppKeys.IsmsDocumentation,
    title: AppTitles.IsmsDocumentation,
    type: AppTypes.Security,
    icon: DgxAppIconAssessments,
    locked: false,
  },
  {
    key: AppKeys.InternalAudit,
    title: AppTitles.InternalAudit,
    type: AppTypes.Security,
    icon: DgxAppIconAssessments,
    locked: false,
  },
  {
    key: AppKeys.ManagementReview,
    title: AppTitles.ManagementReview,
    type: AppTypes.Security,
    icon: DgxAppIconAssessments,
    locked: false,
  },
  {
    key: AppKeys.SecurityQuestionnaires,
    title: AppTitles.SecurityQuestionnaires,
    type: AppTypes.Security,
    icon: DgxAppIconSecurityQuestionnaires,
    locked: false,
  },
  {
    key: AppKeys.Pentesting,
    title: AppTitles.Pentesting,
    type: AppTypes.Security,
    icon: DgxAppIconPentesting,
    locked: false,
  },
  {
    key: AppKeys.IRequestCenter,
    title: AppTitles.IRequestCenter,
    type: AppTypes.Security,
    icon: DgxAppIconIGeneric,
    locked: false,
  },
  // TODO: Following apps are not yet available
  // {
  //   key: 'xdr-eye-security',
  //   title: 'XDR - Eye Security',
  //   type: AppTypes.Security,
  //   icon: DgxAppIconIGeneric,
  //   locked: false,
  // },
  // {
  //   key: 'app-security-detectify',
  //   title: 'App Security - Detectify',
  //   type: AppTypes.Security,
  //   icon: DgxAppIconAppSecurity,
  //   locked: false,
  // },
  {
    key: AppKeys.Audit,
    title: AppTitles.Audit,
    type: AppTypes.Compliance,
    icon: DgxAppIconAssessments,
    locked: false,
  },
  {
    key: AppKeys.DataSubjectRequests,
    title: AppTitles.DataSubjectRequests,
    type: AppTypes.Compliance,
    icon: DgxAppIconDSR,
    locked: false,
  },
  {
    key: AppKeys.Documentation,
    title: AppTitles.Documentation,
    type: AppTypes.Compliance,
    icon: DgxAppIconPGeneric,
    locked: false,
  },
  {
    key: AppKeys.PrivacyPartnerManagement,
    title: AppTitles.PrivacyPartnerManagement,
    type: AppTypes.Compliance,
    icon: DgxAppIconVendors,
    locked: false,
  },
  {
    key: AppKeys.PrivacyPolicy,
    title: AppTitles.PrivacyPolicy,
    type: AppTypes.Compliance,
    icon: DgxAppIconPPG,
    locked: false,
  },
  {
    key: AppKeys.Resources,
    title: AppTitles.Resources,
    type: AppTypes.Compliance,
    icon: DgxAppIconRopa,
    locked: false,
  },
  {
    key: AppKeys.RequestCenter,
    title: AppTitles.RequestCenter,
    type: AppTypes.Compliance,
    icon: DgxAppIconPGeneric,
    locked: false,
  },
  {
    key: AppKeys.CookieManager,
    title: AppTitles.CookieManager,
    type: AppTypes.Compliance,
    icon: DgxAppIconCookieChimp,
    locked: false,
  },
  {
    key: AppKeys.ConsentAndPreferenceManagement,
    title: AppTitles.ConsentAndPreferenceManagement,
    type: AppTypes.Compliance,
    icon: DgxAppIconCPM,
    locked: false,
  },
  {
    key: AppKeys.Whistleblowing,
    title: AppTitles.Whistleblowing,
    type: AppTypes.Compliance,
    icon: DgxAppIconWhistleblowing,
    locked: false,
  },
]

export default allLinks
