import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'
import {
  fetchPathwaysList,
  fetchPathwayTemplatesList,
} from '@/api/pathways.api'
import { useCompaniesStore, useTasksStore } from '@/stores'
import {
  calculateStepProgress,
  composeList,
  composePathway,
} from './pathwaysHelpers'
import { useLocalisations } from '@/composables'
import { useI18n } from 'vue-i18n'
import type {
  ComposedPathway,
  ComposedStep,
  ComposedChecklistItem,
  Localisations,
  ChecklistItem,
} from '@/types'
import type { Task } from '@/stores/tasks'

export const usePathwaysStore = defineStore('pathways', () => {
  const companiesStore = useCompaniesStore()
  const { fetchTasks } = useTasksStore()
  const isLoading = ref(true)
  const isError = ref(false)
  const isEmpty = ref(false)
  const pathways = ref<ComposedPathway[]>([])
  const isFetchingRelatedTasks = ref(false)
  const checklistItemsRelatedTasks = ref<Record<string, Task[]>>({})
  const selectedPathway = ref<ComposedPathway | null>(null)
  const selectedStep = ref<ComposedStep | null>(null)
  const selectedChecklistItem = ref<ComposedChecklistItem | null>(null)
  const { createLocaleTextGetter, mergeLocalisations } = useLocalisations()
  const localisations = ref<Localisations>({})
  const { locale } = useI18n()
  const companyId = computed(
    () => companiesStore.currentCompany?.universalCompanyId
  )

  async function fetchAndComposePathways() {
    if (!companyId.value) return

    try {
      isEmpty.value = false
      isError.value = false
      isLoading.value = true

      const [pathwayList, pathwayTemplateList] = await Promise.all([
        fetchPathwaysList(companyId.value),
        fetchPathwayTemplatesList(companyId.value),
      ])

      if (pathwayList.length === 0 || pathwayTemplateList.length === 0) {
        isEmpty.value = true
        return
      }

      localisations.value = mergeLocalisations(pathwayTemplateList)

      pathways.value = composeList(
        pathwayList,
        pathwayTemplateList,
        'pathway_template_id',
        composePathway
      )

      if (pathways.value.length) {
        // Set the first pathway as the selected one.
        updateSelectedPathway(pathways.value[0].id)
      }
    } catch (err) {
      isError.value = true
    } finally {
      isLoading.value = false
    }
  }
  const getLocaleText: (key: string, preferredLocale?: string) => string =
    createLocaleTextGetter(localisations)

  function updateChecklistItem(
    pathwayId: string,
    checklistItemId: string,
    checklistIem: ChecklistItem
  ) {
    const foundPathway = pathways.value.find((p) => p.id === pathwayId)
    if (!foundPathway) return

    const foundChecklistItem = foundPathway.steps
      .flatMap((step) => step.checklist)
      .find((c) => c.id === checklistItemId)
    if (!foundChecklistItem) return

    foundChecklistItem.completed_at = checklistIem.completed_at
    foundChecklistItem.is_complete = Boolean(checklistIem.completed_at)
    foundChecklistItem.related_task_section_id =
      checklistIem.related_task_section_id ?? ''
  }

  function updateSelectedPathway(id: string) {
    selectedPathway.value = pathways.value.find((p) => p.id === id) ?? null

    // Automatically select the first step and its first checklist item.
    if (!selectedPathway.value) return
    updateSelectedStep(selectedPathway.value.steps[0].id)
  }
  function updateSelectedStep(id: string) {
    selectedStep.value =
      selectedPathway.value?.steps.find((s) => s.id === id) ?? null

    // Automatically select the first checklist item of this step.
    if (!selectedStep.value) return
    updateSelectedChecklistItem(selectedStep.value.checklist[0].id)
  }
  async function updateSelectedChecklistItem(id: string) {
    selectedChecklistItem.value =
      selectedStep.value?.checklist.find((c) => c.id === id) ?? null

    await fetchRelatedTasks()
  }

  const selectedPathwayId = computed({
    get: () => selectedPathway.value?.id,
    set: (newId) => {
      if (!newId) return
      updateSelectedPathway(newId)
    },
  })
  const selectedStepId = computed({
    get: () => selectedStep.value?.id,
    set: (newId) => {
      if (!newId) return
      updateSelectedStep(newId)
    },
  })
  const selectedChecklistItemId = computed({
    get: () => selectedChecklistItem.value?.id,
    set: (newId) => {
      if (!newId) return
      updateSelectedChecklistItem(newId)
    },
  })
  const listOfSelectedPathwayStepProgresses = computed(() => {
    if (!selectedPathway.value) return []

    return selectedPathway.value.steps.map((step) => ({
      id: step.id,
      progress: calculateStepProgress(step),
    }))
  })
  const selectedPathwayOverallProgress = computed(() => {
    const listOfStepProgresses = listOfSelectedPathwayStepProgresses.value
    if (!listOfStepProgresses.length) return 0

    const sumOfAllStepsProgress = listOfStepProgresses.reduce(
      (acc, step) => acc + step.progress,
      0
    )
    const averageProgress = sumOfAllStepsProgress / listOfStepProgresses.length

    return parseFloat(averageProgress.toFixed(0))
  })

  async function fetchRelatedTasks() {
    if (
      !companiesStore.currentCompany?.id ||
      !selectedChecklistItem.value?.related_task_section_id
    ) {
      return
    }
    isFetchingRelatedTasks.value = true
    isError.value = false
    try {
      const tasks = await fetchTasks({
        tag_list: [
          { context: 'checklist_id', value: selectedChecklistItem.value.id },
        ],
      })

      // sort and put the new tasks at the top
      tasks.sort((a: Task, b: Task) => {
        const dateA = new Date(a.created_at ?? '')
        const dateB = new Date(b.created_at ?? '')

        return dateB.getTime() - dateA.getTime()
      })

      checklistItemsRelatedTasks.value[selectedChecklistItem.value.id] = tasks
    } catch (error) {
      isError.value = true
    } finally {
      isFetchingRelatedTasks.value = false
    }
  }

  watch(locale, fetchRelatedTasks)

  function updateRelatedTaskCompletionStatus(taskId: string, status: boolean) {
    if (!selectedChecklistItem.value) return

    const foundTask = checklistItemsRelatedTasks.value[
      selectedChecklistItem.value.id
    ].find((t) => t.id === taskId)
    if (!foundTask) return

    const foundTag = foundTask?.tag_list.find((t) => t.context === 'status')
    if (!foundTag) return

    foundTask.status = status ? 'complete' : 'open'
    foundTag.value = status ? 'complete' : 'open'
  }

  function addNewTaskToRelatedTasklist(checklistItemId: string, newTask: Task) {
    if (!checklistItemsRelatedTasks.value[checklistItemId]) {
      checklistItemsRelatedTasks.value[checklistItemId] = []
    }
    checklistItemsRelatedTasks.value[checklistItemId].unshift(newTask)
  }

  const isRelatedTasksListEmpty = computed(() => {
    if (!selectedChecklistItem.value?.id) return true
    const list =
      checklistItemsRelatedTasks.value[selectedChecklistItem.value.id]
    if (!list) return true

    return list.length === 0
  })

  watch(
    companyId,
    async (newVal) => {
      if (newVal) await fetchAndComposePathways()
    },
    { immediate: true }
  )

  return {
    isLoading,
    isError,
    isEmpty,
    pathways,
    selectedPathway,
    selectedStep,
    selectedChecklistItem,
    selectedPathwayId,
    selectedStepId,
    selectedChecklistItemId,
    listOfSelectedPathwayStepProgresses,
    selectedPathwayOverallProgress,
    getLocaleText,
    fetchAndComposePathways,
    updateChecklistItem,
    isFetchingRelatedTasks,
    isRelatedTasksListEmpty,
    checklistItemsRelatedTasks,
    updateRelatedTaskCompletionStatus,
    addNewTaskToRelatedTasklist,
  }
})
