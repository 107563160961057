<template>
  <ModalBase :title="$t(`product_modal.${tKey}.title`)">
    <section class="grid gap-4 p-8">
      <p
        class="text-lg tracking-tight font-light p-6 bg-light-blue-100 shadow-sm"
      >
        {{ $t('product_modal.first_paragraph') }}
      </p>
      <p
        v-for="paragraph in $tm(`product_modal.${tKey}.paragraphs`)"
        :key="paragraph"
      >
        {{ paragraph }}
      </p>
      <ul>
        <ListItem
          v-for="listItem in $tm(`product_modal.${tKey}.list_items`)"
          :key="listItem"
        >
          {{ listItem }}
        </ListItem>
      </ul>
    </section>
    <button
      class="py-4 px-8 hover:bg-light-blue-100 transition-all duration-200 ease-in-out w-max uppercase tracking-tighter font-semibold ml-auto text-blue-500"
      @click="handleFormLink"
    >
      {{ $t('product_modal.cta') }}
    </button>
  </ModalBase>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import ModalBase from '../ModalBase.vue'
import ListItem from './ListItem.vue'

interface Props {
  tKey: string
}

defineProps<Props>()
const emit = defineEmits(['before-submit'])
const { t } = useI18n()

const handleFormLink = () => {
  emit('before-submit')
  window.open(t('product_modal.form_link'), '_self')
}
</script>
