import type {
  Pathway,
  Step,
  ChecklistItem,
  PathwayTemplate,
  StepTemplate,
  ChecklistItemTemplate,
  ComposedPathway,
  ComposedStep,
  ComposedChecklistItem,
} from '@/types'

function calculateStepProgress(step: ComposedStep): number {
  const completedItems = step.checklist.filter((item) => item.is_complete)
  const progress = (completedItems.length / step.checklist.length) * 100
  return Number(progress.toFixed(2))
}

/**
 * Generic function to compose a list of composed items based on the items and their templates.
 * This function iterates over each template and finds the matching item from the list.
 * Then, it applies a given composeFunction to the matching pair to produce a composed item.
 *
 * @param {Array<T>} list - The array of actual items (e.g., Pathways, Steps, ChecklistItems).
 * @param {Array<U>} templateList - The array of templates for those items.
 * @param {keyof T} templateMatchKey - The key to match an item with its template.
 * @param {(item: T, itemTemplate: U) => V | null} composeFunction - The function used to compose an item and its template into a new composed object.
 * @returns {Array<V>} - The composed list of items.
 */
function composeList<
  T extends ChecklistItem | Step | Pathway,
  U extends ChecklistItemTemplate | StepTemplate | PathwayTemplate,
  V extends ComposedChecklistItem | ComposedStep | ComposedPathway,
>(
  list: T[],
  templateList: U[],
  templateMatchKey: keyof T,
  composeFunction: (item: T, itemTemplate: U) => V | null
): V[] {
  const composedList: V[] = []

  templateList.forEach((templateItem) => {
    // Find the matching item in the list using the provided key
    const foundMatchingItem = list.find(
      (item) => item[templateMatchKey] === templateItem.id
    )
    // Compose the item with its template if a match is found, otherwise set to null
    const composedItem = foundMatchingItem
      ? composeFunction(foundMatchingItem, templateItem)
      : null

    // Add the composed item to the result list if it exists
    if (composedItem) composedList.push(composedItem)
  })

  // Sort the composed list based on the "order" attribute if present (e.g., for steps in a checklist items)
  composedList.sort((a, b) => {
    if ('order' in a && 'order' in b) {
      return a.order - b.order
    }
    return 0
  })

  return composedList
}

/**
 * Composes a checklist item from its base item and template.
 * This includes merging information like titles, descriptions,
 * and tracking completion status.
 *
 * @param {ChecklistItem} item - The base checklist item containing dynamic company specific data.
 * @param {ChecklistItemTemplate} itemTemplate - The template providing static information and structure.
 * @returns {ComposedChecklistItem} - The composed checklist item with combined information from the item and its template.
 */
function composeChecklistItem(
  item: ChecklistItem,
  itemTemplate: ChecklistItemTemplate
): ComposedChecklistItem {
  // Extract template details and item specifics
  const {
    title_key,
    description_key,
    learn_more_link,
    learn_more_link_de,
    suggested_tasks,
    order,
  } = itemTemplate
  const { id, completed_at, related_task_section_id } = item
  const is_complete = Boolean(completed_at)

  return {
    id,
    title_key,
    description_key,
    learn_more_link,
    learn_more_link_de,
    suggested_tasks,
    completed_at,
    related_task_section_id,
    is_complete,
    order,
  }
}

/**
 * Composes a step from its base step and template.
 * It also composes all checklist items within the step by calling
 * composeList for the checklist items.
 *
 * @param {Step} step - The base step containing dynamic company specific data and a list of checklist items.
 * @param {StepTemplate} stepTemplate - The template providing static information for the step and its checklist items.
 * @returns {ComposedStep} - The composed step including composed checklist items.
 */
function composeStep(step: Step, stepTemplate: StepTemplate): ComposedStep {
  // Extract step template details
  const { title_key, order, checklist: checklistTemplate } = stepTemplate
  // Extract step details
  const { id, checklist } = step
  // Compose the checklist items within the step
  const composedChecklist = composeList(
    checklist,
    checklistTemplate,
    'checklist_template_id',
    composeChecklistItem
  )

  return {
    id,
    order,
    title_key,
    checklist: composedChecklist,
  }
}

/**
 * Composes a pathway from its base pathway and template.
 * It composes all steps within the pathway by calling composeList for the steps,
 * which in turn composes all checklist items within each step.
 *
 * @param {Pathway} pathway - The base pathway containing dynamic company specific data and a list of steps.
 * @param {PathwayTemplate} pathwayTemplate - The template providing static information for the pathway and its steps.
 * @returns {ComposedPathway} - The composed pathway including composed steps and their checklist items.
 */
function composePathway(
  pathway: Pathway,
  pathwayTemplate: PathwayTemplate
): ComposedPathway {
  // Extract pathway template details
  const { title_key, steps: stepTemplateList } = pathwayTemplate
  // Extract pathway details
  const { id, steps } = pathway
  // Compose the steps within the pathway
  const composedSteps = composeList(
    steps,
    stepTemplateList,
    'step_template_id',
    composeStep
  )

  return {
    id,
    title_key,
    steps: composedSteps,
  }
}

export { calculateStepProgress, composeList, composePathway }
