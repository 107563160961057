import { FALLBACK_LOCALE } from '@/composables/translations'
import { VALID_LOCALES } from '@/composables/translations'

export default function localeInit() {
  const firstPieceOfPathname = window.location.pathname
    .split('/')
    .slice(1, 2)[0]
  const localeFromLocalStorage = localStorage.getItem('language')

  const pathnameHasValidLocale = VALID_LOCALES.includes(firstPieceOfPathname)

  const actualLocale = pathnameHasValidLocale
    ? firstPieceOfPathname
    : localeFromLocalStorage || FALLBACK_LOCALE

  localStorage.setItem('language', actualLocale)
}
