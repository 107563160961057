import type { RouteLocation, NavigationGuardNext } from 'vue-router'

import { createWebHistory, createRouter } from 'vue-router'
import { useUserStore } from '@/stores'

const routes = [
  {
    path: '/:lang',
    meta: {
      layout: 'default',
    },
    children: [
      {
        name: 'CompanySelection',
        path: 'company-selection',
        component: () => import('./CompanySelectionView.vue'),
        beforeEnter: async (
          _to: RouteLocation,
          _from: RouteLocation,
          next: NavigationGuardNext
        ) => {
          const userStore = useUserStore()
          if (userStore.user && userStore.user.administrative) {
            next()
          } else {
            next({ name: 'Home' })
          }
        },
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('./LoginView.vue'),
        beforeEnter: (
          to: RouteLocation,
          from: RouteLocation,
          next: NavigationGuardNext
        ) => {
          if (to.query.redirect === 'Dashboard') {
            to.meta.layout = 'dashboard'
          }
          next()
        },
      },
      {
        name: 'Home',
        path: '',
        component: () => import('./HomeView.vue'),
      },
      {
        name: 'Dashboard',
        path: 'dashboard',
        component: () => import('./DashboardView.vue'),
        meta: {
          layout: 'dashboard',
        },
      },
      {
        name: 'Error',
        path: 'error',
        component: () => import('./ErrorView.vue'),
      },
      {
        name: 'SelfCheckout',
        path: 'checkout',
        component: () => import('./SelfCheckoutView.vue'),
      },
      {
        name: 'NoAccess',
        path: 'no-access',
        component: () => import('./NoAccessView.vue'),
      },
      {
        name: 'NotFound',
        path: ':pathMatch(.*)*',
        component: () => import('./NotFoundView.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from) => {
  if (!to.params.lang)
    to.params.lang = String(localStorage.getItem('language')) || 'en'

  if (to.name === 'Login') return true
  if (to.name === 'NoAccess') return true

  const userStore = useUserStore()
  if (userStore.user && !Object.keys(userStore.user?.roles || {}).length) {
    return { ...to, name: 'NoAccess' }
  }

  if (
    to.name !== 'Login' &&
    (!localStorage.getItem('access-token') || !userStore.user)
  ) {
    const newRoute = {
      ...to,
      name: 'Login',
    }

    const routeNames = router.getRoutes().map((route) => route.name)
    if (typeof to.name === 'string' && routeNames.includes(to.name)) {
      newRoute.query.redirect = to.name
    }

    return newRoute
  }

  if (to.name !== 'Login') delete to.query.redirect

  return true
})

export default router
