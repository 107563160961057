import { defineStore } from 'pinia'
import { ref, computed, Component } from 'vue'

import { useAppLinks, usePermissions } from '@/composables'

import { FeatureFlags } from '@/types/FeatureFlags'

import {
  ProductKeys,
  ProductLines,
  AppKeys,
  AppTypes,
  AppTitles,
} from '@/types'

import allAppLinks from './data/all-links'

import { useCompaniesStore, useFeatureFlagsStore, useUserStore } from '@/stores'

export type AppItem = {
  key: AppKeys
  title: AppTitles
  type: AppTypes
  link?: string
  icon: Component
  locked: boolean
  isTitleMatch?: boolean
}

type FilterFunction = (app: AppItem) => boolean

export default defineStore('appLinks', () => {
  const searchTerm = ref('')
  const { getAppLink } = useAppLinks()
  const companiesStore = useCompaniesStore()
  const userStore = useUserStore()
  const { hasPermission } = usePermissions()
  const { isFeatureEnabled } = useFeatureFlagsStore()

  const getSubscriptionStatus = (
    product: ProductLines,
    productName: ProductKeys
  ) => {
    if (
      companiesStore.currentProducts &&
      companiesStore.currentProducts[product]
    ) {
      return companiesStore.currentProducts[product][productName].subscribed
    }
    return false
  }

  const subscriptions = computed(() => ({
    compliance: getSubscriptionStatus(
      ProductLines.privacy,
      'privacy_as_a_service'
    ),
    infosecISO: getSubscriptionStatus(
      ProductLines.infosec,
      'infosec_as_a_service_iso27001'
    ),
    infosecTisax: getSubscriptionStatus(
      ProductLines.infosec,
      'infosec_as_a_service_tisax'
    ),
    whistleblowing: getSubscriptionStatus(
      ProductLines.compliance,
      'whistleblowing'
    ),
  }))

  const isPowerUser = computed(() => {
    const currentCompanyId = companiesStore.currentCompany?.id
    return userStore.isPowerUser(currentCompanyId)
  })

  const isLimitedToAcademy = computed(() => {
    const currentCompanyId = companiesStore.currentCompany?.id
    return userStore.isAcademyOnly(currentCompanyId)
  })

  const hasAuditFunctionRole = computed(() => {
    const currentCompanyId = companiesStore.currentCompany?.id
    return userStore.hasAuditFunctionRole(currentCompanyId)
  })

  const featureFlagMapWithAppKeys: { [key: string]: string } = {
    [AppKeys.Configuration]: FeatureFlags.CONFIG_APP,
    [AppKeys.Profile]: FeatureFlags.PROFILE_LINK,
    [AppKeys.Frameworks]: FeatureFlags.FRAMEWORKS_APP,
    [AppKeys.SecurityQuestionnaires]: FeatureFlags.SECURITY_QUESTIONAIRES,
    [AppKeys.CookieManager]: FeatureFlags.COOKIE_MANAGER,
  }

  const checkUserRoles = (app: AppItem) => {
    let shouldIncludeApp = true

    switch (app.type) {
      case AppTypes.Compliance:
        if (subscriptions.value.compliance) {
          if (isLimitedToAcademy.value) {
            shouldIncludeApp = [AppKeys.RequestCenter].includes(app.key)
          } else if (hasAuditFunctionRole.value) {
            shouldIncludeApp = [AppKeys.Audit, AppKeys.RequestCenter].includes(
              app.key
            )
          }
        }
        break

      case AppTypes.Security:
        if (
          subscriptions.value.infosecISO ||
          subscriptions.value.infosecTisax
        ) {
          if (isLimitedToAcademy.value) {
            shouldIncludeApp = [AppKeys.IRequestCenter].includes(app.key)
          }
        }
        break
    }

    return shouldIncludeApp
  }

  const checkFeatureFlags = (app: AppItem) => {
    if (featureFlagMapWithAppKeys[app.key]) {
      return isFeatureEnabled(featureFlagMapWithAppKeys[app.key])
    }
    return true
  }

  const checkPermissions = (app: AppItem) => {
    if (userStore.user?.administrative) return true
    let hasAccess = true
    switch (app.key) {
      case AppKeys.Configuration:
        hasAccess = hasPermission('config:entity_hierarchy:view')
        break
      case AppKeys.Frameworks:
      case AppKeys.Integrations:
        hasAccess = isPowerUser.value
        break
      case AppKeys.Tasks:
        hasAccess = !isLimitedToAcademy.value
        break
      case AppKeys.CookieManager:
        hasAccess = hasPermission('cookies:admin')
        break
    }
    return hasAccess
  }

  const applyFilters = (filters: FilterFunction[]) => {
    const passedItems: AppItem[] = []

    allAppLinks.forEach((item) => {
      let passesAllFilters = true
      for (let filter of filters) {
        if (!filter(item)) {
          passesAllFilters = false
          break
        }
      }
      if (passesAllFilters) {
        passedItems.push(item)
      }
    })

    return passedItems
  }

  const filteredAppList = computed(() => {
    return applyFilters([checkUserRoles, checkFeatureFlags, checkPermissions])
  })

  const getAppLockStatus = (app: AppItem) => {
    let isAppLocked = false

    switch (app.type) {
      case AppTypes.Compliance:
        if (app.key === AppKeys.Whistleblowing) {
          isAppLocked = !subscriptions.value.whistleblowing
        } else if (app.key === AppKeys.ConsentAndPreferenceManagement) {
          isAppLocked = true
        } else {
          isAppLocked = !subscriptions.value.compliance
        }
        break
      case AppTypes.Security:
        if (app.key === AppKeys.Pentesting) {
          isAppLocked = true
        } else {
          isAppLocked =
            !subscriptions.value.infosecISO && !subscriptions.value.infosecTisax
        }
        break
    }

    return isAppLocked
  }

  const getSearchTermMatch = (app: AppItem) => {
    let isTitleMatch = false

    if (searchTerm.value) {
      const appTitle = app.title.toLowerCase()
      const searchTermLower = searchTerm.value.toLowerCase()
      isTitleMatch = appTitle.includes(searchTermLower)
    }

    return isTitleMatch
  }

  const enhancedAppList = computed(() =>
    filteredAppList.value.map((app) => ({
      ...app,
      locked: getAppLockStatus(app),
      link: getAppLink(app.key),
      isTitleMatch: getSearchTermMatch(app),
    }))
  )

  const numberOfFilteredApps = computed(() => {
    return enhancedAppList.value.filter((app) => app.isTitleMatch).length
  })

  const hasSubscribedToApp = (key: string) => {
    if (key === AppTypes.Security)
      return subscriptions.value.infosecISO || subscriptions.value.infosecTisax
    if (key === AppTypes.Compliance) return subscriptions.value.compliance
    return false
  }

  return {
    searchTerm,
    appTypes: AppTypes,
    hasSubscribedToApp,
    numberOfFilteredApps,
    list: enhancedAppList,
  }
})
