<template>
  <ModalTemplateParagraphsAndList
    t-key="pentesting"
    @before-submit="trackEnquireEvent('Pentesting', 'security')"
  />
</template>

<script setup lang="ts">
import { useEnquireModal } from '@/composables'
import ModalTemplateParagraphsAndList from './ModalTemplateParagraphsAndList.vue'

const { trackEnquireEvent } = useEnquireModal()
</script>
