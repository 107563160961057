import { useErrorStore } from '@/stores'
import { useI18n } from 'vue-i18n'

export default function useDefaultError() {
  const { t } = useI18n()
  const errorStore = useErrorStore()

  const displayError = (key: string) => {
    errorStore.setError({
      key,
      title: t('error.default_title'),
      description: t('error.default_message'),
    })
  }

  return {
    displayError,
  }
}
