export enum AppTitles {
  Academy = 'Academy',
  Audit = 'Audit',
  Assets = 'Assets',
  Configuration = 'Configuration',
  ConsentAndPreferenceManagement = 'Consent & Preference Management',
  CookieManager = 'Cookie Manager',
  DataSubjectRequests = 'Data subject requests',
  Documentation = 'Documentation',
  Frameworks = 'Frameworks',
  GapAnalysis = 'Gap Analysis',
  InternalAudit = 'Internal Audit',
  Integrations = 'Integrations',
  IRequestCenter = 'Request Center',
  IsmsDocumentation = 'ISMS Documentation',
  KnowledgeCenter = 'Knowledge Center',
  ManagementReview = 'Management Review',
  News = 'News',
  Pentesting = 'Pentesting',
  Policies = 'Policies',
  PrivacyPartnerManagement = 'Privacy Partner Management',
  PrivacyPolicy = 'Privacy Policy',
  Profile = 'Profile',
  RequestCenter = 'Request Center',
  Resources = 'Resources',
  Risks = 'Risks',
  SecurityQuestionnaires = 'Security Questionnaires',
  Tasks = 'Tasks',
  Whistleblowing = 'Whistleblowing',
}
