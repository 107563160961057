declare global {
  const Keycloak: KeycloakConstructor
}

interface KeycloakConstructor {
  new (arg0: KeycloakConfig): KeycloakInstance
}

interface KeycloakInstance {
  token: string
  refreshToken: string
  init: (options: {
    onLoad?: string
    pkceMethod?: string
    enableLogging?: boolean
    checkLoginIframe?: boolean
    redirectUri?: string
    silentCheckSsoRedirectUri?: string
    token?: string
    refreshToken?: string
  }) => Promise<boolean>
  updateToken: (minValidity: number) => Promise<boolean>
  logout: (options: { redirectUri: string }) => Promise<void>
}

type KeycloakConfig = {
  url: string
  realm: string
  clientId: string
}

import { AxiosInstance } from 'axios'

import { useEnv } from '@/composables'
declare const window: any

let keycloakInstance: KeycloakInstance

const init = (config: KeycloakConfig) => {
  keycloakInstance = new Keycloak({
    url: `${config.url}`,
    realm: config.realm,
    clientId: config.clientId,
  })
}

const fetchEnvDataAndInit = async () => {
  try {
    const { env } = useEnv()
    const authConfig = env.authConfig
    init(authConfig as KeycloakConfig)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

const clearGainsightCookies = () => {
  const gainsightCookiesExist =
    document.cookie.includes('apt.uid') && document.cookie.includes('apt.sid')
  if (gainsightCookiesExist) {
    document.cookie = 'apt.uid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    document.cookie = 'apt.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }

  if (window.aptrinsic) {
    window.aptrinsic('reset')
  }
}

const login = async () => {
  await fetchEnvDataAndInit()
  clearGainsightCookies()
  const auth = await keycloakInstance.init({
    onLoad: 'login-required',
    pkceMethod: 'S256',
    enableLogging: true,
    checkLoginIframe: false,
    token: localStorage.getItem('access-token') ?? undefined,
    refreshToken: localStorage.getItem('refresh-token') ?? undefined,
  })
  if (!auth) {
    window.location.reload()
  }

  if (!keycloakInstance.token || !keycloakInstance.refreshToken)
    throw new Error('An authentication problem has occurred')

  localStorage.setItem('access-token', keycloakInstance.token)
  localStorage.setItem('refresh-token', keycloakInstance.refreshToken)

  return keycloakInstance.token
}

const refreshToken = async () => {
  try {
    const refreshed = await keycloakInstance.updateToken(30)
    if (refreshed && keycloakInstance.token) {
      localStorage.setItem('access-token', keycloakInstance.token)
    }
    return keycloakInstance.token
  } catch (error) {
    return null
  }
}

const logout = async () => {
  clearGainsightCookies()
  localStorage.clear()

  const logoutOptions = { redirectUri: window.location.origin }

  await keycloakInstance.logout(logoutOptions)
}

const setupAuthHandlers = (axios: AxiosInstance) => {
  axios.interceptors.request.use(
    async (request) => {
      request.headers['Content-Type'] = 'application/json'
      if (localStorage.getItem('refresh-token')) {
        await refreshToken()

        const token = localStorage.getItem('access-token')
        if (request.headers) {
          request.headers['Authorization'] = `Bearer ${token}`
        }
      }
      if (request.data instanceof FormData) {
        delete request.headers['Content-Type']
      }

      return request
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      if (error.code === 'ERR_NETWORK') {
        return Promise.reject(error)
      }
      if (error.response.status === 401) {
        const token = await login()

        error.config.headers['Authorization'] = `Bearer ${token}`
        return axios.request(error.config)
      }
      return Promise.reject(error)
    }
  )
}

export { init, login, logout, refreshToken, setupAuthHandlers }
