import axios from 'axios'

export async function getAnonymousAccessToken(): Promise<string | null> {
  try {
    const response = await axios.get('/anonymous-access-token')
    return response.data.accessToken
  } catch (error) {
    console.warn('Failed to get anonymous access token')
    return null
  }
}
