import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { defineStore } from 'pinia'

import { copilot } from '@/services'
import { useFeatureFlagsStore, useUserStore, useCompaniesStore } from '@/stores'
import { FeatureFlags } from '@/types/FeatureFlags'

type Status =
  | 'idle'
  | 'processing'
  | 'rejected'
  | 'accepted'
  | 'mounted'
  | 'error'

export const useCopilotStore = defineStore('copilot', () => {
  const { startCopilot, stopCopilot } = copilot
  const { locale } = useI18n()
  const companiesStore = useCompaniesStore()
  const userStore = useUserStore()

  const userName = computed(() => userStore.user?.name)
  const userEmail = computed(() => userStore.user?.email)
  const companyId = computed(() => companiesStore.currentCompany?.id)

  const status = ref<Status>('idle')

  const s = computed(() => ({
    idle: status.value === 'idle',
    processing: status.value === 'processing',
    rejected: status.value === 'rejected',
    accepted: status.value === 'accepted',
    error: status.value === 'error',
    mounted: status.value === 'mounted',
  }))

  const featureFlagsStore = useFeatureFlagsStore()

  const prepareCopilot = async () => {
    status.value = 'processing'
    try {
      status.value = 'accepted'
    } catch {
      status.value = 'error'
    }
  }

  const start = async () => {
    if (s.value.idle || s.value.rejected) {
      await prepareCopilot()
    }

    if (!s.value.accepted) return

    if (
      !userName.value ||
      !companyId.value ||
      !locale.value ||
      !userEmail.value
    ) {
      status.value = 'rejected'
      return
    }
    try {
      startCopilot({
        identifier: userEmail.value,
        metadata: {
          userName: userName.value,
          companyId: companyId.value,
          locale: locale.value,
        },
      })
      status.value = 'mounted'
    } catch {
      status.value = 'error'
    }
  }

  const stop = () => {
    if (!s.value.mounted) return

    try {
      stopCopilot()
      status.value = 'idle'
    } catch {
      status.value = 'error'
    }
  }

  /**
   * Watch for changes in feature flags state and show/hide copilot accordingly
   *
   * Feature flags are fetched once for each selected company
   */
  watch(
    [
      companyId,
      locale,
      () => featureFlagsStore.isFeatureEnabled(FeatureFlags.PALI_COPILOT),
    ],
    async () => {
      stop()
      await start()
    }
  )
  return { start, stop }
})
