import { ref, toRefs } from 'vue'
import { taskKeys } from '@/utils/constants'

export type TaskStatus = (typeof taskKeys.STATUS)[keyof typeof taskKeys.STATUS]

export interface Tag {
  context: string
  value: string
}

export interface Task {
  assignee_id: string
  completed_at: string | null
  creator_id: string
  description: string
  due_at: string | null
  id: string
  title: string
  task_list_id: string
  status?: TaskStatus
  external_reference_id: null
  tag_list: Tag[]
}

export interface TaskTemplate {
  priority: string
  description: string
  title: string
}

interface State {
  isOpen: boolean
  task: TaskTemplate | null
  createdTaskId: string
  showSuccess: boolean
}

const state = ref<State>({
  isOpen: false,
  task: null,
  createdTaskId: '',
  showSuccess: false,
})

const useTaskModal = () => {
  const closeModal = () => {
    state.value.isOpen = false
    state.value.task = null
  }

  const createTask = (task?: TaskTemplate) => {
    state.value.isOpen = true
    // The task will point to suggested task template
    // Eventually be used for pre-populating the modal with task details
    if (task) state.value.task = task
  }

  const showTaskCreationSuccess = (taskId: string) => {
    state.value.createdTaskId = taskId
    state.value.showSuccess = true
    setTimeout(() => {
      state.value.createdTaskId = ''
      state.value.showSuccess = false
    }, 5000)
  }

  return {
    ...toRefs(state.value),
    closeModal,
    createTask,
    showTaskCreationSuccess,
  }
}

export default useTaskModal
