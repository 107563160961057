import axios from 'axios'
import type {
  Pathway,
  ChecklistItem,
  PathwayTemplate,
  UpdateChecklistParams,
} from '@/types'

const BASE_URL = '/pathways'

async function makeRequest<T>(
  method: 'GET' | 'PUT',
  url: string,
  params?: Record<string, any>,
  data?: Record<string, any>
): Promise<T> {
  try {
    const response = await axios({
      method,
      url: `${BASE_URL}${url}`,
      params,
      data,
    })
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { data, status } = error.response || {}
      throw new Error(
        `Failed to ${method} ${url} with status code: ${status}. Message: ${data.message}`
      )
    } else {
      console.error('Unexpected error:', error)
      throw error
    }
  }
}

async function fetchPathwaysList(companyId: string) {
  return makeRequest<Pathway[]>('GET', `/pathways`, { company_id: companyId })
}

async function fetchPathwayTemplatesList(companyId: string) {
  return makeRequest<PathwayTemplate[]>('GET', `/pathway_templates`, {
    company_id: companyId,
  })
}

async function updateChecklistItem(params: UpdateChecklistParams) {
  const { companyId, pathwayId, checklistId, data } = params
  return makeRequest<ChecklistItem>(
    'PUT',
    `/pathways/${pathwayId}/checklists/${checklistId}`,
    { company_id: companyId },
    { data }
  )
}

export { fetchPathwayTemplatesList, fetchPathwaysList, updateChecklistItem }
