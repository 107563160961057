import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getCompanies, getCompany } from '@/api/companies.api'
import { getAccountProducts } from '@/api/accountService.api'
import { Loaders, useLoadingStore } from './loading'
import { useFeatureFlagsStore } from './featureFlags'
import useDefaultError from '@/composables/defaultError'
import { ProductKeys, ProductLines } from '@/types'

type ProductsAPI = {
  [key in ProductLines]: PlatformProducts
}

type PlatformProducts = {
  [key in ProductKeys]: {
    subscribed: boolean
    url: string | null
  }
}

export interface Company {
  id: string
  name: string
  universalCompanyId: string
  userCentricsEnabled: boolean
  kNumber: string
}

export const useCompaniesStore = defineStore('companies', () => {
  const router = useRouter()
  const route = useRoute()

  const loadingStore = useLoadingStore()
  const { displayError } = useDefaultError()
  const { fetchFeatureFlags } = useFeatureFlagsStore()

  const companies = ref<Company[]>([])
  const currentCompany = ref<Company | undefined>()
  const currentProducts = ref<ProductsAPI>()
  const companiesAndProducts = ref<Map<string, ProductsAPI>>(new Map())

  const fetchCompanies = async (companyIds?: string[]) => {
    loadingStore.startLoading(Loaders.companies)
    try {
      const companiesResponse = await getCompanies(companyIds)
      const companiesData = companiesResponse.data
      setCompanies(companiesData)
      if (companies.value.length) {
        const companyFromUrl = companies.value.find(
          (company: Company) => `${company.id}` === route.query.companyId
        )
        const initialCompany = companyFromUrl ?? companies.value[0]
        setCurrentCompany(initialCompany)
      } else {
        setCurrentCompany(undefined)
      }
    } catch (error) {
      console.error(error)
      displayError('companies')
    } finally {
      loadingStore.stopLoading(Loaders.companies)
    }
  }

  const fetchCompany = async (companyId: string) => {
    loadingStore.startLoading(Loaders.company)
    try {
      const companyResponse = await getCompany(companyId)
      setCurrentCompany(companyResponse.data)
    } catch (error) {
      console.error(error)
      displayError('company')
    } finally {
      loadingStore.stopLoading(Loaders.company)
    }
  }

  const setCompanies = (newCompanies: Company[]) => {
    companies.value = newCompanies
  }

  const setCurrentCompany = async (company: Company | undefined) => {
    currentCompany.value = company
  }

  const fetchProducts = async () => {
    loadingStore.startLoading(Loaders.products)
    if (!currentCompany.value) {
      loadingStore.stopLoading(Loaders.products)
      return
    }
    const cachedProducts = companiesAndProducts.value.get(
      currentCompany.value.id
    )
    try {
      if (cachedProducts) {
        currentProducts.value = cachedProducts
        return
      }
      const productsResponse = await getAccountProducts(currentCompany.value.id)
      const products = productsResponse.data
      currentProducts.value = products
      companiesAndProducts.value.set(currentCompany.value.id, products)
    } catch (error) {
      console.error(error)
      displayError('companies')
    } finally {
      loadingStore.stopLoading(Loaders.products)
    }
  }

  watch(currentCompany, (newVal) => {
    fetchProducts()
    fetchFeatureFlags(newVal?.id)
  })

  return {
    companies,
    currentCompany,
    currentProducts,
    fetchProducts,
    setCompanies,
    fetchCompanies,
    fetchCompany,
  }
})
