import { createPinia } from 'pinia'
import { createApp } from 'vue'

import { useTranslations } from './composables'
import { localeInit } from './utils'
import { initSentry } from './services'

import App from './App.vue'
import router from './router'

import sentinel from '@task-force/sentinel-ds-vue3'
import '@task-force/sentinel-ds-vue3/dist/sentinel-ds-vue3.css'
import '@dataguard/product-design-system/dist/style.css'

import './style.css'
import './assets/fonts/Averta/Averta.css'

declare global {
  interface Window {
    aptrinsic: any
    isGainsightPXLoaded: boolean
  }
}

localeInit()

const pinia = createPinia()
const i18n = useTranslations()

const app = createApp(App)

initSentry(app, router)
app.use(router)
app.use(sentinel)
app.use(pinia)
app.use(i18n)
app.mount('#app')

/**
 * If the user visits the page from the back button, Gainsight doesn't execute again.
 * For that reason, we need to refresh the page when we identify that.
 */
window.onpageshow = function (event) {
  if (event.persisted) {
    location.reload()
  }
}
