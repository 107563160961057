import { defineStore } from 'pinia'
import { ref } from 'vue'

export enum Loaders {
  user = 'user',
  companies = 'companies',
  company = 'company',
  tasks = 'tasks',
  products = 'products',
  root = 'root',
  header = 'header',
  features = 'features',
}

interface LoadingStatus {
  [key: string]: boolean
}

export const useLoadingStore = defineStore('loading', () => {
  const loadingStatus = ref<LoadingStatus>({
    root: true,
    user: true,
    products: true,
    companies: false,
    company: false,
    tasks: true,
    features: true,
  })
  const startLoading = (key: Loaders) => {
    loadingStatus.value[key] = true
  }

  const stopLoading = (key: Loaders) => {
    delete loadingStatus.value[key]
  }

  const stopLoadingAll = () => {
    loadingStatus.value = {}
  }

  return {
    loadingStatus,
    startLoading,
    stopLoading,
    stopLoadingAll,
  }
})
