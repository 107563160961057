import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useUserStore } from '@/stores'
import { useEnv } from '@/composables'

export default function useViewportType() {
  const { userTokenForArchbeeLinks } = useUserStore()
  const { env } = useEnv()
  const { locale } = useI18n()

  const localeBasedArchbeeLink = computed(() => {
    return locale.value === 'en' ? env.helpSpaceUrl : env.helpSpaceDeUrl
  })

  const indexLink = computed(() => {
    if (!localeBasedArchbeeLink.value) return '#'

    let query = ''
    if (userTokenForArchbeeLinks)
      query += `?jwt=${userTokenForArchbeeLinks}&reload`

    return localeBasedArchbeeLink.value + query
  })

  return {
    indexLink,
  }
}
