import { App } from 'vue'
import { Router } from 'vue-router'
import * as Sentry from '@sentry/vue'

export default function initSentry(vueApp: App, router: Router) {
  if (!import.meta.env.SENTRY_DSN) return

  Sentry.init({
    app: vueApp,
    environment: import.meta.env.MODE,
    release: import.meta.env.BUILD_COMMIT_SHA,
    dsn: import.meta.env.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    ignoreErrors: [
      'TypeError: NetworkError when attempting to fetch resource.',
    ],
    tracePropagationTargets: [/home(-)?(\.stg)?\.dataguard\.de/, /^\//],
    tracesSampleRate: 0.25,
    trackComponents: true,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    logErrors: true,
  })
}
