import { useCompaniesStore, useUserStore } from '@/stores'

const usePermissions = () => {
  const userStore = useUserStore()
  const companiesStore = useCompaniesStore()
  const hasPermission = (permissionKey: string) => {
    if (!companiesStore.currentCompany) return false
    return userStore.hasPermission(
      companiesStore.currentCompany.id,
      companiesStore.currentCompany.universalCompanyId,
      permissionKey
    )
  }

  return { hasPermission }
}

export default usePermissions
