<template>
  <dg-header
    @handle-event="handleHeaderEvents"
    :enabled-langs="enabledLangs"
    :user="userStore.user"
    :companies="companyListForHeader"
    :menu-links="showHomeNavButton ? menuLinks : []"
    :no-profile="isProfileDropdownHidden"
  >
    <template #profile>
      <ul
        data-test="header-company-selector"
        class="overflow-y-auto max-w-sm max-h-[calc(100vh_-_200px)]"
      >
        <li
          class="text-light-blue-500 text-sm cursor-default px-3 pb-0 pt-3 uppercase"
        >
          {{ t('home.companies') }}
        </li>
        <li
          v-for="(company, index) in companyStore.companies"
          :key="company.id"
        >
          <ProfileCompanyDropdownItem
            :company="company"
            :selected="company.id === companyStore.currentCompany?.id"
            :class="{ 'border-t-0': index !== 0 }"
            @click="selectCompany(company)"
          />
        </li>
      </ul>
    </template>
  </dg-header>
  <TheTopBar v-if="isCustomerViewForConsultant" />
</template>

<script setup lang="ts">
import { Loaders } from '@/stores/loading'
import ProfileCompanyDropdownItem from './ProfileCompanyDropdownItem.vue'
import type { Company } from '@/stores/companies'

import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useLoadingStore, useUserStore, useCompaniesStore } from '@/stores'
import { logout } from '@/services/auth'
import TheTopBar from '@/components/TheTopBar.vue'

type LanguageObject = {
  label: string
  active: boolean
  key: string
  altText: string
}

const userStore = useUserStore()
const companyStore = useCompaniesStore()
const loadingStore = useLoadingStore()
const route = useRoute()
const router = useRouter()
const { t, locale } = useI18n()

const selectCompany = async (company: Company) => {
  if (company.id === companyStore.currentCompany?.id) return
  companyStore.currentCompany = company
  router.push({
    ...route,
    query: {
      ...route.query,
      companyId: company.id,
    },
  })

  /**
   * We only want Gainsight to identify users that are not administrative.
   * It's also important that we don't include personal data on the 'identify' call
   * to avoid potential data breaches.
   */
  if (window.aptrinsic && !userStore.user?.administrative) {
    window.aptrinsic(
      'identify',
      { id: userStore.user?.id, role: userStore.user?.role },
      { id: company.universalCompanyId, name: company.name }
    )
  }
}

const setLocale = (lang: LanguageObject) => {
  locale.value = lang.key
  router.push({
    query: {
      ...route.query,
    },
    params: {
      lang: lang.key,
    },
  })
}

const enabledLangs = computed<LanguageObject[]>(() => {
  return [
    {
      label: t('language.german'),
      active: route.params.lang === 'de',
      key: 'de',
      altText: t('language.german'),
    },
    {
      label: t('language.english'),
      active: route.params.lang === 'en',
      key: 'en',
      altText: t('language.english'),
    },
  ]
})

const handleHeaderEvents = (
  type: 'switch-lang' | 'logout',
  selectedItem: unknown
) => {
  const actionMap = {
    'switch-lang': () => setLocale(selectedItem as LanguageObject),
    logout: handleLogout,
  }

  if (!actionMap[type]) {
    throw new Error('Action not allowed')
  }

  actionMap[type]()
}

const handleLogout = async () => {
  loadingStore.startLoading(Loaders.root)
  loadingStore.startLoading(Loaders.user)
  await logout()
  loadingStore.stopLoadingAll()
}

const showHomeNavButton = computed(() => {
  if (route.name === 'Home') return false
  if (route.name === 'Dashboard') return false
  if (route.name === 'Login') return false
  if (route.name === 'CompanySelection') return false
  return true
})

const isProfileDropdownHidden = computed(() => {
  if (!userStore.user) return true
  if (userStore.user.administrative) return true
  if (!companyStore.companies.length) return true
  return false
})

const menuLinks = computed(() => [
  {
    name: t('back_to_home'),
    attrs: {
      to: { name: 'Home', query: { companyId: route.query.companyId } },
    },
  },
])

const companyListForHeader = computed(() => {
  const isActiveCompany = (company: Company) => {
    const idToCompare = !isNaN(Number(route.query.companyId))
      ? String(company.id)
      : company.universalCompanyId
    return idToCompare === route.query.companyId
  }

  if (userStore.isAdmin && companyStore.currentCompany) {
    return [
      {
        ...companyStore.currentCompany,
        active: isActiveCompany(companyStore.currentCompany),
      },
    ]
  }

  if (companyStore.companies.length > 0) {
    return companyStore.companies.map((company) => ({
      ...company,
      active: isActiveCompany(company),
    }))
  }

  return []
})

const isCustomerViewForConsultant = computed(() => {
  if (!userStore.isAdmin) return false
  const { companyId, appName } = route.query
  if (typeof appName !== 'string') return
  if (typeof companyId !== 'string' || !companyId) return
  if (!['Infosec', 'Privacy'].includes(appName)) return
  return userStore.isAdmin
})
</script>
