<template>
  <div
    class="bg-white min-h-screen flex flex-col items-center antialiased"
    :class="{
      overlay: isTaskModalOpen,
      'bg-main-background': layout === DashboardLayout,
    }"
  >
    <component :is="layout" :key="$route.fullPath" />
  </div>
  <dg-notification
    :show="Boolean(errorStore.error)"
    type="error"
    @close="errorStore.resetError()"
  >
    <template #title>
      {{ errorStore.error?.title }}
    </template>
    <template #description>
      {{ errorStore.error?.description }}
    </template>
  </dg-notification>
</template>

<script setup lang="ts">
import { onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTitle } from '@vueuse/core'
import axios, { AxiosInstance } from 'axios'
import { useRoute, RouterView } from 'vue-router'
import { setupAuthHandlers } from '@/services/auth'
import { useErrorStore, useLoadingStore } from '@/stores'
import { useHtmlLangAttribute, useEnv, useTaskModal } from '@/composables'

import DashboardLayout from '@/layouts/DashboardLayout.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'

import { Loaders } from './stores/loading'

const loadingStore = useLoadingStore()
const errorStore = useErrorStore()
const { fetchEnvData } = useEnv()
const { isOpen: isTaskModalOpen } = useTaskModal()

const { setHtmlLangAttribute } = useHtmlLangAttribute()
const { t, locale } = useI18n()

const setupLocaleHandler = (ax: AxiosInstance) => {
  ax.interceptors.request.use(async (request) => {
    request.headers['Accept-Language'] = locale.value
    return request
  })
}

onMounted(async () => {
  setHtmlLangAttribute()
  await fetchEnvData()
})

setupAuthHandlers(axios)
setupLocaleHandler(axios)

loadingStore.stopLoading(Loaders.root)
const title = computed(() => {
  return t('page_title_v2')
})
const route = useRoute()
const layout = computed(() => {
  switch (route.meta.layout) {
    case 'dashboard':
      return DashboardLayout
    case 'default':
      return DefaultLayout
    default:
      return null
  }
})
useTitle(title)
</script>

<style scope>
.overlay::before {
  content: '';
  display: block;
  position: fixed;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  z-index: 25;
  animation: fade-in 150ms ease-in-out forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
