import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useCompaniesStore } from '@/stores'
import { MetadataKeys } from '@/types/GainsightEvents'

type TrackEventPayload = {
  metadata: { [key: string]: string }
  eventType: string
}

export default function useTrackEvents() {
  const companiesStore = useCompaniesStore()
  const { currentCompany } = storeToRefs(companiesStore)

  const { locale } = useI18n()

  return (payload: TrackEventPayload) => {
    if (!window.aptrinsic) return

    const populatedEventData = {
      ...payload.metadata,
      [MetadataKeys.Language]: locale.value,
      [MetadataKeys.CompanyId]: currentCompany.value?.universalCompanyId,
      [MetadataKeys.CompanyName]: currentCompany.value?.name,
      [MetadataKeys.ProductName]: 'home',
    }

    window.aptrinsic('track', payload.eventType, populatedEventData)
  }
}
