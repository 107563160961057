import axios from 'axios'

export const getCompanies = async (companyIds?: string[]) => {
  try {
    return axios.get('/companies', { params: { companyIds } })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getCompany = async (companyId: string) => {
  try {
    return axios.get(`/companies/${companyId}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getCompaniesByName = async (search: string) => {
  try {
    return axios.get(`/companies?name=${search}`)
  } catch (error) {
    return Promise.reject(error)
  }
}
