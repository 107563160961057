<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useEnv } from '@/composables'
import { useCompaniesStore } from '@/stores'
import { useRoute } from 'vue-router'
import { DgxIconArrowLeft, DgxIconLock } from '@dataguard/product-design-system'

const { locale, t } = useI18n()
const { env } = useEnv()
const route = useRoute()
const companiesStore = useCompaniesStore()
const companyId = computed(
  () => companiesStore.currentCompany?.universalCompanyId
)
const backButtonLink = computed(() => {
  if (env === null) return

  const { appName } = route.query

  const appUrl = appName === 'Infosec' ? env.infosecUrl : env.privacyUrl
  return `${appUrl}/${locale.value}/administrative/companies/${companyId.value}`
})
</script>

<template>
  <nav
    class="sticky top-16 h-10 w-full z-10 border-solid border-b border-l-0 border-r-0 border-t-0 border-[#e6d4b8] flex justify-between font-bold bg-[#f5eee3] px-5 py-1"
    data-test="consultant-only-top-bar"
  >
    <a class="flex items-center gap-1 p-2 text-[14px]" :href="backButtonLink">
      <DgxIconArrowLeft />
      {{ t('home.top_bar.back_link') }}
    </a>
    <span
      class="text-[12px] uppercase text-[#CA9E6D] flex gap-1 items-center p-2"
    >
      <DgxIconLock :size="12" />
      <p class="self-baseline">{{ t('home.top_bar.consultant_only_label') }}</p>
    </span>
  </nav>
</template>
