export enum FeatureFlags {
  SNC_KNOWLEDGE_CENTER_APP_LINK = 'snc_home:knowledge_center_archbee_link',
  PALI_COPILOT = 'pali_copilot',
  PATHWAYS_WIDGET = 'pathways:pic_home_widget',
  CONFIG_APP = 'config_app',
  PROFILE_LINK = 'profile_link',
  FRAMEWORKS_APP = 'frameworks_app:app',
  FRAMEWORKS_QUICKLINK = 'home:frameworks_quicklink',
  SECURITY_QUESTIONAIRES = 'security_questionnaire',
  COOKIE_MANAGER = 'cookie_manager',
}
