export enum MetadataKeys {
  EntityType = 'Entity type',
  Language = 'Language',
  CompanyId = 'Company Id',
  CompanyName = 'Company Name',
  ProductName = 'Product name',
  PathwayName = 'Pathway name',
  StepName = 'Step name',
  ChecklistItemName = 'Checklist item name',
  TaskName = 'Task name',
  AppTitle = 'App title',
  AppType = 'App type',
  AppLocked = 'App locked',
}

export enum EventType {
  UserClicks = 'Pathway Widget: User clicks',
  UserCompletes = 'Pathway Widget: User completes',
  AppsSectionUserClicks = 'S&C Apps: User clicks',
  AppsSectionEnquireNow = 'S&C Apps: Enquire now',
}

export enum EntityType {
  Pathway = 'Pathway',
  Step = 'Step',
  ChecklistItem = 'Checklist',
  LearnMore = 'Learn more',
  SuggestedTasks = 'Suggested tasks',
  CreateTask = 'Create task',
  Task = 'Task',
}

type AdditionalMetadata = {
  metadata: Omit<
    {
      [key in MetadataKeys]?: string
    },
    MetadataKeys.EntityType
  >
}

// Define types for events with metadata
export type PathwayWidgetUserClicksEvent = {
  eventType: EventType.UserClicks
  metadata: {
    [MetadataKeys.EntityType]:
      | EntityType.Pathway
      | EntityType.Step
      | EntityType.ChecklistItem
      | EntityType.LearnMore
      | EntityType.SuggestedTasks
      | EntityType.CreateTask
  }
} & AdditionalMetadata

export type PathwayWidgetUserCompletesEvent = {
  eventType: EventType.UserCompletes
  metadata: {
    [MetadataKeys.EntityType]:
      | EntityType.ChecklistItem
      | EntityType.Task
      | EntityType.Pathway
  }
} & AdditionalMetadata
