import axios from 'axios'
import type { RawCompanyUser } from '@/types'

type Response<T> = {
  data: T
}

/* For fetching employee users to be shown in Create task form */
export const getCompanyUsers = async (companyId: string) => {
  if (!companyId) return Promise.reject('No company id provided')
  return axios.get<Response<RawCompanyUser[]>>(
    `/company-users/companies/${companyId}/users`
  )
}
