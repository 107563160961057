declare global {
  interface Window {
    mountChainlitWidget: (options: IWidgetConfig) => void
    isCopilotWidgetLoaded: boolean
  }
}

export interface IWidgetConfig {
  chainlitServer: string
  showCot?: boolean
  accessToken?: string
  theme?: 'light' | 'dark'
  fontFamily?: string
  button?: {
    containerId?: string
    imageUrl?: string
    style?: {
      size?: string
      bgcolor?: string
      color?: string
      bgcolorHover?: string
      borderColor?: string
      borderWidth?: string
      borderStyle?: string
      borderRadius?: string
      boxShadow?: string
    }
  }
}

type ChainlitCallEvent = Event & {
  detail?: Record<string, any>
}

import * as Sentry from '@sentry/vue'
import { getCopilotAccessToken } from '@/api/copilotAccessToken.api'
import { useEnv } from '@/composables'
import type { CopilotMetadata } from '@/types'

const loadCopilotWidget = (
  onLoadCallback: ((this: GlobalEventHandlers, ev: Event) => any) | null
) => {
  if (window.isCopilotWidgetLoaded) return

  const { env } = useEnv()

  const chainlitScript = document.createElement('script')
  chainlitScript.defer = true
  chainlitScript.src = `${env.copilotWidgetUrl}/copilot/index.js`
  chainlitScript.type = 'text/javascript'

  chainlitScript.onload = onLoadCallback
  chainlitScript.onerror = () => {
    Sentry.captureException(
      `Failed to load the copilot widget from: ${chainlitScript.src}`
    )
  }

  const firstScript = document.scripts[0]
  firstScript.parentNode?.insertBefore(chainlitScript, firstScript)
  document.head.appendChild(chainlitScript)

  window.isCopilotWidgetLoaded = true
}

const chainlitListener = (e: ChainlitCallEvent) => {
  if (!e.detail) return
  const { args, callback } = e.detail
  callback(`You sent: ${args.msg}`)
}

const startCopilot = async (metadata: CopilotMetadata) => {
  const accessToken = await getCopilotAccessToken(metadata)
  const { env } = useEnv()

  loadCopilotWidget(() => {
    if (!window.mountChainlitWidget) {
      return
    }
    // this can be removed when new version of copilot is released
    const css = document.createElement('link')
    css.href = '/chainlit-copilot.css'
    css.rel = 'stylesheet'
    css.type = 'text/css'
    window.document.head.appendChild(css)

    window.addEventListener('chainlit-call-fn', chainlitListener)
    window.mountChainlitWidget({
      chainlitServer: env.copilotWidgetUrl,
      showCot: false,
      accessToken,
      button: {
        imageUrl: '/ai_icon_alpha.webp',
      },
    })
  })
}

const stopCopilot = () => {
  document.querySelector('#chainlit-copilot')?.remove()
  document.querySelector('#chainlit-copilot-popover')?.remove()
  window.removeEventListener('chainlit-call-fn', chainlitListener)
}

export { startCopilot, stopCopilot }
