import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { AppKeys, AppTypes } from '@/types'
import { FeatureFlags } from '@/types/FeatureFlags'
import {
  useCompaniesStore,
  useTasksStore,
  useFeatureFlagsStore,
  useUserStore,
} from '@/stores'
import { stripString } from '@/utils'

import useEnv from './env'
import useKnowledgeCenterLink from './knowledgeCenterLink'

const useAppLinks = () => {
  const { env } = useEnv()
  const { locale } = useI18n()
  const route = useRoute()
  const companiesStore = useCompaniesStore()
  const userStore = useUserStore()
  const tasksStore = useTasksStore()
  const { indexLink } = useKnowledgeCenterLink()
  const { isFeatureEnabled } = useFeatureFlagsStore()

  const platformId = computed(() => companiesStore.currentCompany?.id)

  const localeAndPlatformId = computed(
    () => `${locale.value}/${platformId.value}`
  )

  const companyUniversalId = computed(
    () => companiesStore.currentCompany?.universalCompanyId
  )

  const redWorldAppLinks = computed(
    () =>
      `${env.redworldAppUrl}/${locale.value}/c/${companiesStore.currentCompany?.kNumber}`
  )

  const knowledgeCenterAppLink = computed(() => {
    if (isFeatureEnabled(FeatureFlags.SNC_KNOWLEDGE_CENTER_APP_LINK)) {
      return indexLink.value
    }
    return `${env.knowledgeCenterUrl}/${locale.value}`
  })

  const risksQueryParams = computed(() => {
    if (userStore.isAdmin) return `?companyId=${companyUniversalId.value}`
    return `?companyName=${stripString(companiesStore.currentCompany?.name)?.toLowerCase()}`
  })

  const genericAppLinks = computed(() => ({
    [AppKeys.Academy]: `${env.academyUrl}/${locale.value}/${companyUniversalId.value}`,
    [AppKeys.News]: `${env.newsUrl}/${platformId.value}`,
    [AppKeys.Configuration]: `${env.configAppUrl}/${localeAndPlatformId.value}/entities`,
    [AppKeys.Profile]: `${env.profileUrl}?kc_locale=${locale.value}&companyId=${platformId.value}`,
    [AppKeys.Tasks]: tasksStore.taskLinkBase,
    [AppKeys.CookieManager]: env.cookieManagerUrl,
    [AppKeys.Frameworks]: `${env.frameworksAppUrl}/${localeAndPlatformId.value}`,
    [AppKeys.Policies]: `${env.policiesUrl}/${locale.value}/${companyUniversalId.value}/dashboard`,
    [AppKeys.KnowledgeCenter]: knowledgeCenterAppLink.value,
    [AppKeys.Integrations]: `${env.integrationsAppUrl}?companyId=${platformId.value}`,
  }))

  const securityAppLinks = computed(() => ({
    [AppKeys.Assets]: `${env.infosecUrl}/${locale.value}/assets/dashboard?companyId=${companyUniversalId.value}`,
    [AppKeys.Risks]: `${env.infosecUrl}/risk/${locale.value}/query-company${risksQueryParams.value}`,
    [AppKeys.GapAnalysis]: `${env.infosecUrl}/${locale.value}/assessments/infosec?companyId=${companyUniversalId.value}`,
    [AppKeys.IsmsDocumentation]: `${env.infosecUrl}/${locale.value}/assessments/policy_management?companyId=${companyUniversalId.value}`,
    [AppKeys.InternalAudit]: `${env.infosecUrl}/${locale.value}/assessments/internal_audit?companyId=${companyUniversalId.value}`,
    [AppKeys.ManagementReview]: `${env.infosecUrl}/${locale.value}/assessments/management_review?companyId=${companyUniversalId.value}`,
    [AppKeys.SecurityQuestionnaires]: `${env.securityQuestionnairesUrl}/${localeAndPlatformId.value}/questionnaires`,
    [AppKeys.IRequestCenter]: `${env.infosecUrl}/${locale.value}/request-center?companyId=${companyUniversalId.value}`,
  }))

  const complianceAppLinks = computed(() => ({
    [AppKeys.Audit]: `${env.privacyUrl}/${locale.value}/assessments/audit?companyId=${companyUniversalId.value}`,
    [AppKeys.PrivacyPolicy]: `${env.privacyUrl}/${locale.value}/assessments/privacy_policy?companyId=${companyUniversalId.value}`,
    [AppKeys.Documentation]: `${env.privacyUrl}/${locale.value}/documents?companyId=${companyUniversalId.value}`,
    [AppKeys.RequestCenter]: `${env.privacyUrl}/${locale.value}/request-center?companyId=${companyUniversalId.value}`,
    [AppKeys.CookieManager]: env.cookieManagerUrl,
    [AppKeys.DataSubjectRequests]: `${redWorldAppLinks.value}/dsr`,
    [AppKeys.PrivacyPartnerManagement]: `${redWorldAppLinks.value}/ppm`,
    [AppKeys.Resources]: `${env.redworldAppUrl}/redirect/${locale.value}/t/cs/${companiesStore.currentCompany?.kNumber}/ts`,
  }))

  const talkToUsLink = computed<{ [key: string]: string }>(() => ({
    [AppTypes.Security]: `${env.infosecUrl}/${locale.value}/dashboard?companyId=${companyUniversalId.value}&talkToUs=yes`,
    [AppTypes.Compliance]: `${env.privacyUrl}/${locale.value}/dashboard?companyId=${companyUniversalId.value}&talkToUs=yes`,
  }))

  const linksMap = computed<{ [key: string]: string }>(() => ({
    ...genericAppLinks.value,
    ...securityAppLinks.value,
    ...complianceAppLinks.value,
  }))

  const getTalkToUsLink = (key: string): string => {
    return talkToUsLink.value[key]
  }

  const getAppLink = (key: string): string => {
    return linksMap.value[key]
  }

  return { getAppLink, getTalkToUsLink }
}

export default useAppLinks
