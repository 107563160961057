<template>
  <LoaderHeader v-if="loadingStore.loadingStatus.user" />
  <TheHeader v-else />

  <LoaderBody v-if="loadingStore.loadingStatus.root" />
  <div v-else class="max-w-7xl w-full mt-[4rem]">
    <div class="min-h-screen-context pb-8">
      <RouterView />
    </div>
  </div>

  <TheFooter />
</template>

<script setup lang="ts">
import TheFooter from '@/components/TheFooter.vue'
import TheHeader from '@/components/TheHeader.vue'
import LoaderHeader from '@/components/LoaderHeader.vue'
import LoaderBody from '@/components/LoaderBody.vue'
import { useLoadingStore } from '@/stores'

const loadingStore = useLoadingStore()
</script>
