import { ref, toRefs } from 'vue'
import axios from 'axios'
import { defineStore } from 'pinia'
import { useLoadingStore } from '@/stores'
import { Loaders } from '@/stores/loading'

interface State {
  featureFlags: string[]
  isFetched: boolean
  lastFetchedFor?: string
}

const state = ref<State>({
  featureFlags: [],
  isFetched: false,
})

export const useFeatureFlagsStore = defineStore('featureFlags', () => {
  const loadingStore = useLoadingStore()

  const fetchFeatureFlags = async (companyId?: string) => {
    if (companyId && state.value.lastFetchedFor === companyId) return

    let featuresEndpoint = `/features`
    if (companyId) {
      state.value.lastFetchedFor = companyId
      featuresEndpoint += `?company_id=${companyId}`
    }

    try {
      loadingStore.startLoading(Loaders.features)
      const { data } = await axios.get(featuresEndpoint)
      state.value.featureFlags = data.enabled

      state.value.isFetched = true
    } catch (e) {
      console.error(e)
    } finally {
      loadingStore.stopLoading(Loaders.features)
    }
  }

  const isFeatureEnabled = (feature: string) =>
    state.value.featureFlags.includes(feature)

  return {
    ...toRefs(state.value),
    isFeatureEnabled,
    fetchFeatureFlags,
  }
})
