import { watch } from 'vue'
import { useStorage } from '@vueuse/core'

export default function useHtmlLangAttribute() {
  const language = useStorage('language', localStorage.getItem('language'))

  const setHtmlLangAttribute = () => {
    if (language.value) {
      document.querySelector('html')?.setAttribute('lang', language.value)
    }
  }

  watch(language, setHtmlLangAttribute)

  return {
    language,
    setHtmlLangAttribute,
  }
}
