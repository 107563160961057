<template>
  <div
    class="bg-dark-blue-500/50 inset-0 fixed px-4 py-8 z-[1001] grid place-items-center overflow-y-auto"
  >
    <div
      class="relative bg-white w-full max-w-2xl shadow-md flex flex-col p-2"
      v-bind="$attrs"
    >
      <div class="flex items-start border-b border-b-light-blue-300">
        <h2 class="text-2xl text-dark-blue-500 p-6 leading-6 tracking-tighter">
          {{ title }}
        </h2>
        <button
          class="cross relative block hover:bg-light-blue-100 transition-all duration-200 ease-in-out z-40 ml-auto"
          :aria-label="$t('close')"
          @click="emit('close-modal')"
        ></button>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted } from 'vue'

interface Props {
  title?: string
}

const emit = defineEmits(['close-modal'])

defineProps<Props>()

const closeOnEscape = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    emit('close-modal')
  }
}

onMounted(() => {
  document.body.style.overflow = 'hidden'
  document.body.addEventListener('keydown', closeOnEscape)
})

onBeforeUnmount(() => {
  document.body.style.overflow = 'auto'
  document.body.removeEventListener('keydown', closeOnEscape)
})
</script>

<style scoped lang="postcss">
.cross {
  --line-width: 0.125rem;
  --line-length: 1.5rem;
  --side: 3rem;
  width: var(--side);
  height: var(--side);

  &::before,
  &::after {
    @apply bg-dark-blue-500 absolute;

    content: '';
    top: 50%;
    left: 50%;
    width: var(--line-length);
    height: var(--line-width);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
</style>
