import * as Sentry from '@sentry/vue'
import { watch } from 'vue'
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { getCompanyUsers } from '@/api/companyUsers.api'
import { useCompaniesStore } from '@/stores/companies'
import type { CompanyUser, RawCompanyUser } from '@/types'

const processCompanyUserData = (data: RawCompanyUser[]): CompanyUser[] => {
  return data.map((user) => ({
    user_id: user.user_id,
    full_name: user.dont_use?.full_name || '',
  }))
}

export const useCompanyUsersStore = defineStore('company-users', () => {
  const isFetching = ref(false)
  const companiesStore = useCompaniesStore()
  const listOfCompanyUsers = ref<CompanyUser[]>([])
  const companyId = computed(() => companiesStore.currentCompany?.id)

  const fetchCompanyUsers = async () => {
    try {
      isFetching.value = true
      const { data } = await getCompanyUsers(companyId.value as string)
      listOfCompanyUsers.value = processCompanyUserData(data.data)
    } catch (e) {
      Sentry.captureException(e)
    } finally {
      isFetching.value = false
    }
  }

  watch(
    companyId,
    async (newVal) => {
      if (newVal) await fetchCompanyUsers()
    },
    { immediate: true }
  )

  return {
    listOfCompanyUsers,
    isFetching,
  }
})
