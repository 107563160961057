import { Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { Localisations } from '@/types'

type LocalisableObject = {
  localisations: Localisations
  [key: string]: any
}

function useLocalisations() {
  const { locale } = useI18n()

  function mergeLocalisations(
    templateList: LocalisableObject[]
  ): Localisations {
    return templateList.reduce((acc, currentTemplate) => {
      const locales = ['en', 'de']

      locales.forEach((locale) => {
        acc[locale] = {
          ...acc[locale],
          ...currentTemplate.localisations[locale],
        }
      })

      return acc
    }, {} as Localisations)
  }

  function createLocaleTextGetter(localisations: Ref<Localisations>) {
    return function getLocaleText(key: string, preferredLocale?: string) {
      if (preferredLocale) {
        return localisations.value[preferredLocale]?.[key]
      }

      const textInCurrentLocale = localisations.value[locale.value]?.[key]
      if (textInCurrentLocale) {
        return textInCurrentLocale
      }

      const textInEnglish = localisations.value['en']?.[key]
      if (textInEnglish) {
        return textInEnglish
      }

      return 'This information is not available in your language.'
    }
  }

  return {
    createLocaleTextGetter,
    mergeLocalisations,
  }
}

export default useLocalisations
