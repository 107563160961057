import { defineStore } from 'pinia'
import { ref, computed, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { getTasks } from '@/api/tasks.api'
import { Loaders, useLoadingStore } from './loading'
import { useUserStore } from '@/stores/user'
import { useEnv } from '@/composables'
import { useCompaniesStore } from '@/stores/companies'
import useDefaultError from '@/composables/defaultError'
import type { FetchTaskParams } from '@/api/tasks.api'

export const statuses = [
  'open',
  'in_progress',
  'in_review',
  'complete',
] as const

export const priorities = ['unassigned', 'low', 'medium', 'high'] as const

export type TaskStatus = (typeof statuses)[number]
export type TaskPriority = (typeof priorities)[number]
export const sections = ['general', 'infosec', 'privacy']
export type TaskSection = (typeof sections)[number]

export interface Tag {
  context: string
  value: string
}
export interface Task {
  assignee_id: string
  completed_at: string | null
  description: string
  due_at: string | null
  id: string
  title: string
  task_list_id: unknown[] | null
  external_reference_id: null
  tag_list: Tag[]
  parent_id: string | null
  status?: TaskStatus
  created_at?: string
}

export const useTasksStore = defineStore('tasks', () => {
  const loadingStore = useLoadingStore()
  const { displayError } = useDefaultError()
  const { env } = useEnv()
  const { locale } = useI18n()
  const companiesStore = useCompaniesStore()
  const userStore = useUserStore()

  const tasks = ref<Task[]>([])

  const taskLinkBase = computed(() => {
    return `${env.tasksUrl}/${locale.value}/${companiesStore.currentCompany?.universalCompanyId}/tasks`
  })

  const companyId = computed(() => {
    return companiesStore.currentCompany?.universalCompanyId
  })

  const fetchTasks = async (params?: FetchTaskParams) => {
    if (!companyId.value) return
    try {
      const tasksResponse = await getTasks(companyId.value, params)
      return tasksResponse.data?.data
    } catch (error) {
      displayError('tasks')
    }
  }

  watchEffect(async () => {
    if (!userStore.user?.id) return
    loadingStore.startLoading(Loaders.tasks)

    tasks.value = (await fetchTasks({ assignee_id: userStore.user?.id })) || []
    loadingStore.stopLoading(Loaders.tasks)
  })

  return {
    tasks,
    fetchTasks,
    taskLinkBase,
  }
})
