import { defineStore } from 'pinia'
import { ref } from 'vue'

interface Error {
  key: string
  title: string
  description: string
  code?: string | number
}

export const useErrorStore = defineStore('error', () => {
  const error = ref<Error>()

  const setError = (newError: Error) => {
    error.value = newError
  }

  const resetError = () => {
    error.value = undefined
  }

  return {
    error,
    setError,
    resetError,
  }
})
