<template>
  <ModalTemplateParagraphsAndList
    t-key="cpm"
    @before-submit="
      trackEnquireEvent('Consent & Preference Management', 'compliance')
    "
  />
</template>

<script setup lang="ts">
import { useEnquireModal } from '@/composables'
import ModalTemplateParagraphsAndList from './ModalTemplateParagraphsAndList.vue'

const { trackEnquireEvent } = useEnquireModal()
</script>
